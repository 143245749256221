import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { devices } from './Common'
import colors from '../css/colors.scss'


class Header extends Component {
    render() {
        return (
            <FixedMenu>
                <Logo />
                <Scan to='/'>
                    <ScanText>Scan</ScanText>
                    <ScanIcon />
                </Scan>
            </FixedMenu>
        )
    }
}

const FixedMenu = styled.header`
    height: 70px;
    width: 100%;
    left: 0;
    top: 0;
    padding: 0 40px;
    border-bottom: 1px solid ${colors.dark_blue};
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    z-index: 100000;

    ${devices.small} {
        padding: 0 20px;
    }
`

const Logo = styled.div`
    background: url(/static/images/sprite.svg) no-repeat -10px -10px;
    width: 130px;
    height: 22px;
`

const Scan = styled(Link)`
    display: flex;
    align-items: center;
    text-decoration: none;
    color: ${colors.dark_blue};
    font-family: Favorit;
    font-size: 20px;
    text-transform: uppercase;
    height: 18px;
    line-height: 20px;
`

const ScanText = styled.div`
    height: 17px;
`

const ScanIcon = styled.div`
    background: url(/static/images/sprite.svg) no-repeat -10px -50px;
    width: 18px;
    height: 18px;
    display: inline-block;
    margin-left: 5px;
`

export { Header }
