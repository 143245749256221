import React, { Component, createRef } from 'react'
import styled from 'styled-components'
import DocumentMeta from 'react-document-meta'
import { Loading } from './'

class Iframe extends Component {
    canvas = createRef()

    state = {
        iframe: {},
        loaded: false,
    }

    componentDidMount() {
        let url = this.props.computedMatch.params.url
        if (url.includes('%2F')) {
            url = encodeURIComponent(url)
        }
        url = url.replace(/\./g, encodeURIComponent('%2E'))
        fetch(`/api/iframe/${url}/`).then(res => res.json()).then(iframe => this.setState({ iframe }))
    }

    loaded = () => this.setState({ loaded: true })

    render() {
        const meta = {
            title: `Tech Hub`,
        }

        return (
            <IframeContainer>
                <DocumentMeta { ...meta } />
                { !this.state.loaded ? <Loading /> : '' }
                <IframeComponent onLoad={ this.loaded } src={ this.state.iframe.url } />
            </IframeContainer>
        )
    }
}

const IframeContainer = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
`

const IframeComponent = styled.iframe`
    position: fixed;
    top: 70px;
    left: 0;
    width: 100vw;
    height: calc(100vh - 70px);
`

export { Iframe }
