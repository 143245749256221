import { getCookie } from '../_helpers'

export const sendError = (error, stack, url) => {
    const requestOptions = {
        method: 'POST',
        credentials: 'same-origin',
        headers: {
            'X-CSRFToken': getCookie.getCookie('csrftoken'),
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ error, url, stack }),
    }

    return fetch(`/send-error/`, requestOptions)
}
