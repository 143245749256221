import React, { Component } from 'react'
import styled from 'styled-components'
import DocumentMeta from 'react-document-meta'

class NotFound extends Component {
    render() {
        const meta = {
            title: `Not Found | Tech Hub`,
        }

        return (
            <NotFoundContainer className='content' id='not-found'>
                <DocumentMeta { ...meta } />
                <div>
                    We could not find the requested room, please try scanning another QR code.
                </div>
            </NotFoundContainer>
        )
    }
}

const NotFoundContainer = styled.div`
    height: calc(100vh - 435px);
    display: flex;
    justify-content: center;
    align-items: center;
`

export { NotFound }
