export const BrowserDetect = () => {
    const searchString = (data) => {
        for (let i = 0; i < data.length; i++) {
            const dataString = data[i].string;
            if (dataString.indexOf(data[i].subString) !== -1) return data[i].identity
        }
    }

    const dataBrowser = [
        { string: navigator.userAgent, subString: 'Edge', identity: 'MS Edge' },
        { string: navigator.userAgent, subString: 'MSIE', identity: 'Explorer' },
        { string: navigator.userAgent, subString: 'Trident', identity: 'Explorer' },
        { string: navigator.userAgent, subString: 'Firefox', identity: 'Firefox' },
        { string: navigator.userAgent, subString: 'Opera', identity: 'Opera' },
        { string: navigator.userAgent, subString: 'OPR', identity: 'Opera' },
        { string: navigator.userAgent, subString: 'Chrome', identity: 'Chrome' },
        { string: navigator.userAgent, subString: 'Safari', identity: 'Safari' },
    ]

    const browser = searchString(dataBrowser) || 'Other'
    return browser
}

export const checkMedia = () => {
    const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    if (!((navigator.mediaDevices && navigator.mediaDevices.getUserMedia) || navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia)) {
        console.error('This project must be served over https in a compatible browser');

        if (iOS) {
            document.getElementById('msg').innerHTML = 'Please use Safari or the Camera app to scan QR codes.';
        }
    }
};
