import React, { Component } from 'react'
import styled from 'styled-components'
import DocumentMeta from 'react-document-meta'
import colors from '../css/colors.scss'
import { devices } from './Common'
import { Loading } from './'

class Room extends Component {
    state = {
        room: {
            skills: [],
            vendors: [],
            inventory: [],
        }
    }

    componentDidMount() {
        const roomNumber = this.props.computedMatch.params.roomNumber
        fetch(`/api/room/${roomNumber}/`).then(res => res.json()).then(room => this.setState({ room }))
    }

    componentWillUnmount() {
        this.setState({
            room: {
                skills: [],
                vendors: [],
                inventory: [],
            }
        })
    }

    render() {
        const { room } = this.state
        const { skills, vendors, inventory } = room
        const meta = {
            title: `${room.title} | Tech Hub`,
        }
        if (!room.room_number) return <Loading />
        return (
            <RoomContainer>
                <DocumentMeta { ...meta } />
                <SubTitle marginbottom={ 10 }>
                    Room { room.room_number}
                </SubTitle>
                <Title>
                    { room.title }
                </Title>
                <TwoColumn large={ 1 }>
                    <Col>
                        <Description dangerouslySetInnerHTML={{ __html: room.description }} />
                    </Col>
                    {skills.length ? (
                        <Col>
                            <HR></HR>
                            <SubTitle>
                                Alexa Skills
                            </SubTitle>
                            {skills.map(e => (
                                <Skill key={ e.order }>
                                    &ldquo;{ e.skill_text }&rdquo;
                                </Skill>
                            ))}
                        </Col>
                    ) : '' }
                </TwoColumn>
                <TwoColumn>
                    <Col>
                        <HR></HR>
                        <SubTitle>
                            Technology Inventory
                        </SubTitle>
                        {inventory.length ? inventory.map((e, i) => (
                            <Item key={ e.name }>
                                <AlphaIndex>
                                    <span>{ 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'[i] }</span>
                                </AlphaIndex>
                                <div dangerouslySetInnerHTML={{ __html: e.name }} />
                            </Item>
                        )) : ''}
                    </Col>
                    {vendors.length ? (
                        <Col>
                            <HR></HR>
                            <SubTitle>
                                Vendors
                            </SubTitle>
                            {vendors.map(e => (
                                <Vendor key={ e.name }>
                                    { e.name }
                                </Vendor>
                            ))}
                        </Col>
                    ) : '' }
                </TwoColumn>
            </RoomContainer>
        )
    }
}

const RoomContainer = styled.div`
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    padding: 40px;
    padding-bottom: 100px;
    position: fixed;
    top: 70px;

    ${devices.small} {
        padding: 20px;
        padding-bottom: 100px
    }
`

const SubTitle = styled.div`
    font-family: Supply;
    font-size: 15px;
    margin-bottom: ${({ marginbottom=25 }) => marginbottom }px;
    text-transform: uppercase;
    height: 15px;
    line-height: 15px;
`

const Title = styled.div`
    font-family: Favorit;
    font-size: 38px;
    margin-bottom: 20px;
`

const TwoColumn = styled.div`
    display: flex;

    ${devices.small} {
        display: block;
    }
    ${({ large=0 }) => large ? (`
        display: block;

        ${devices.large} {
            display: flex !important;
        }
    `) : ''}
`

const Col = styled.div`
    flex: 0.5 0 0;
`

const Description = styled.div`
    font-family: Supply;
    font-weight: 200;
    font-size: 15px;
    max-width: 450px;
    line-height: 17px;
`

const HR = styled.div`
    height: 1px;
    width: 100px;
    background: ${colors.dark_blue};
    margin: 40px 0;
`

const Skill = styled.div`
    margin-bottom: 30px;
    font-size: 38px;

    &:last-of-type {
        margin-bottom: 0;
    }
`

const Item = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 20px;
    max-width: 450px;
    padding-right: 40px;
    font-family: Supply;
    font-weight: 200;
    font-size: 15px;

    ${devices.small}  {
        padding-right: 0;
    }
`

const AlphaIndex = styled.div`
    background: ${colors.yellow};
    border-radius: 50%;
    margin-top: 4px;
    margin-right: 15px;

    span {
        height: 16px;
        width: 16px;
        font-size: 8px;
        font-weight: 400;
        display: flex;
        justify-content: center;
        align-items: center;
    }
`

const Vendor = styled.div`
    font-family: Supply;
    font-weight: 200;
    font-size: 15px;
    margin-bottom: 20px;
`

export { Room }
