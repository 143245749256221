exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "\n", "", {"version":3,"sources":[],"names":[],"mappings":"","file":"colors.scss","sourceRoot":""}]);

// exports
exports.locals = {
	"light_blue": "#bde6e9",
	"gradient_blue1": "#b4dce0",
	"gradient_blue2": "#88b1bb",
	"dark_blue": "#1e4660",
	"mobile_gradient1": "#769eab",
	"mobile_gradient_end": "#4b7386",
	"yellow": "#ffed29",
	"black": "#000",
	"white": "#fff"
};